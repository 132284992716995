//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { loadedLanguages } from '@/lang/index';
import { saveToLocal } from '@/utils/cache';

export default {
  data() {
    return {
      items: loadedLanguages(),
      lang: '',
    };
  },
  created() {
    this.lang = this.$store.state.user.lang;
  },
  methods: {
    changeLang(val) {
      saveToLocal('userInfo', 'lang', val);
      window.location.reload();
    },
  },
};
